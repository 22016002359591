import price from '../images/party-price.webp';

const Party = () => {
    return(
        <div className='party-menu'>
                <div className='party-blurb' initial={{ y: '40vh', opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} viewport={{ once: true }} transition={{ duration: 1 }}>
                    <div>
                        <p>Included in the 2 hours of fun:</p>
                        <ul>
                            <li>Mud kitchen</li>
                            <li>Ball/Water run</li>
                            <li>Construction area</li>
                            <li>Swing, hammock and ladder</li>
                            <li>Picnic Area</li>
                            <li>Campfire & S'mores</li>
                            <li>T-shirt for birthday child</li>
                        </ul>
                        <h4>Request a form via email @:</h4>
                        <button onClick={(e) => {window.location.href ='mailto:puddymuddlesplaygroup@gmail.com';}}>Booking Requests</button>
                </div>
                <div>
                    <img src={price} alt='from £205' />
                </div>
                </div>
            </div>
    )
}

export default Party;